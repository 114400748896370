import React from "react";
import styled from "styled-components";

const InventoryList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
`;

const InventoryItem = styled.li`
  margin-bottom: 0.5rem;
`;

const Inventory = ({ inventory, items }) => {
  return (
    <InventoryList>
      {inventory.map((itemId) => (
        <InventoryItem key={itemId}>
          <b>{items[itemId].name}:</b> {items[itemId].description}
        </InventoryItem>
      ))}
    </InventoryList>
  );
};

export default Inventory;
