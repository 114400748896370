import React from "react";
import styled from "styled-components";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0 2rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  text-shadow: 0 0 10px #333;
  font-family: "Cormorant Garamond", serif;
  margin: 2rem 0;
  text-transform: lowercase;
`;

const Header = () => {
  return (
    <HeaderContainer>
      <Title>managing terror</Title>
    </HeaderContainer>
  );
};

export default Header;
