import React, { useState } from "react";
import styled from "styled-components";
import LocationDescription from "./LocationDescription";
import Inventory from "./Inventory";
import InputAndButton from "./InputAndButton";

const GameContainerStyle = styled.div`
  width: 800px;
  max-width: 90%;
  background-color: #222;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 5px;
`;

const GameContainer = ({
  playerPosition,
  setPlayerPosition,
  inventory,
  setInventory,
  locations,
  items,
}) => {
  const [userInput, setUserInput] = useState("");

  const handleAction = () => {
    const [verb, noun] = userInput.toLowerCase().split(" ");
    const currentLocationData = locations[`${playerPosition.x},${playerPosition.y}`];
    const actions = currentLocationData.actions;

    if (verb === "go" && actions.go.includes(noun)) {
      let newPosition = { ...playerPosition };
      switch (noun) {
        case "north":
          newPosition.y += 1;
          break;
        case "south":
          newPosition.y -= 1;
          break;
        case "east":
          newPosition.x += 1;
          break;
        case "west":
          newPosition.x -= 1;
          break;
        default:
          // If it's not a direction, assume it's a named location
          newPosition = getLocationCoordinates(noun, locations); 
      }
      setPlayerPosition(newPosition);
      setUserInput("");
    } else if (verb === "take" && actions.take.includes(noun)) {
      setInventory([...inventory, noun]);
      setUserInput("");
    } else if (verb === "examine" && actions.examine.includes(noun)) {
      const itemToExamine = noun; 
      const itemDescription =
        items[itemToExamine]?.description ||
        `You examine the ${itemToExamine}, but find nothing remarkable about it.`;
      alert(itemDescription);
      setUserInput("");
    } else {
      console.log("Invalid action");
    }
  };

  // Helper function to find coordinates of a named location
  const getLocationCoordinates = (locationName, locations) => {
    for (const [key, value] of Object.entries(locations)) {
      if (value.name === locationName) {
        const [x, y] = key.split(",");
        return { x: parseInt(x), y: parseInt(y) };
      }
    }
    return playerPosition; // Return current position if not found
  };


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAction();
    }
  };

  const currentLocationData = locations[`${playerPosition.x},${playerPosition.y}`];

  // Check if the current location requires an item to enter
  if (
    currentLocationData.requires &&
    !currentLocationData.requires.every((item) => inventory.includes(item))
  ) {
    currentLocationData.description = "You need a specific item to proceed.";
    currentLocationData.actions = { go: ["south"] }; // Only allow going back
  }

  return (
    <GameContainerStyle>
      <LocationDescription description={currentLocationData.description} />
      <Inventory inventory={inventory} items={items} />
      <InputAndButton
        userInput={userInput}
        setUserInput={setUserInput}
        handleAction={handleAction}
        handleKeyPress={handleKeyPress}
      />
    </GameContainerStyle>
  );
};

export default GameContainer;
