import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import GameContainer from "./components/GameContainer";
import Header from "./components/Header";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');
  body { 
    margin: 0; 
  }
`;

const Container = styled.div`
  background-color: #121212;
  color: #eee;
  font-family: "Inconsolata", monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-height: 100vh;
`;

const App = () => {
  const [playerPosition, setPlayerPosition] = useState({ x: 3, y: 2 });
  const [inventory, setInventory] = useState([]);

  const locations = {
    "3,2": {
      description: `You stand at the entrance of a dimly lit cave. 
The air is damp and cold, and you can hear water dripping in the distance.
There is a path leading north into the darkness.`,
      items: [],
      actions: {
        go: ["north"],
        examine: ["cave entrance"],
      },
    },
    "3,3": {
      description: `The cave narrows, and the path becomes more treacherous. 
You can see a faint light ahead, but the way is blocked by a sturdy iron gate.`,
      items: [],
      actions: {
        go: ["south"],
        examine: ["gate"],
      },
    },
    "3,4": {
      description: `The light reveals a small chamber with a glistening pool of water. 
A sense of peace and tranquility pervades the air.`,
      items: ["water"],
      actions: {
        go: ["south"],
        take: ["water"],
        examine: ["pool"],
      },
      requires: ["rustyKey"],
    },
    "2,2": {
      description: `A narrow passage leads to a small alcove. 
You can see a glint of metal in the corner.`,
      items: ["rustyKey"],
      actions: {
        go: ["east"],
        take: ["rustyKey"],
        examine: ["alcove"],
      },
    },
    "4,2": {
      description: `The path opens into a larger cavern, the walls adorned with strange symbols. 
There is a sense of ancient power lingering in the air.`,
      items: [],
      actions: {
        go: ["west"],
        examine: ["symbols"],
      },
    },
    "3,1": {
      description: `The cave descends into a deeper darkness. 
You can hear the rushing of water below.`,
      items: [],
      actions: {
        go: ["north"],
        examine: ["darkness"],
      },
    },
    "3,0": {
      description: `You find yourself at the edge of an underground river. 
The current is strong, and the water is icy cold.`,
      items: ["boat"],
      actions: {
        go: ["south"],
        take: ["boat"],
        examine: ["river"],
      },
      requires: ["water"],
    },
    // Add more locations here...
  };

  const items = {
    rustyKey: {
      name: "Rusty Key",
      description: "An old, rusty key. It might unlock something...",
    },
    water: {
      name: "Water",
      description:
        "A vial of clear, shimmering water. It feels strangely warm to the touch.",
    },
    oldClock: {
      name: "Old Clock",
      description:
        "A worn pocket watch with a cracked face, frozen at 11:11. It's a stark reminder that time marches on, indifferent to our hopes and dreams.",
    },
    willowBranch: {
      name: "Willow Branch",
      description:
        "A slender branch from a weeping willow, its leaves whispering secrets of life and death, renewal and decay. It speaks of the cyclical nature of existence, the constant ebb and flow of time.",
    },
    mementoMori: {
      name: "Memento Mori",
      description:
        "A small skull carved from bone, a chilling reminder of mortality. It urges you to contemplate the fleeting nature of existence, the preciousness of each passing moment.",
    },
    boat: {
      name: "Boat",
      description:
        "A small, sturdy boat. It looks like it could withstand the rough currents of the underground river.",
    },
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Header />
        <GameContainer
          playerPosition={playerPosition}
          setPlayerPosition={setPlayerPosition}
          inventory={inventory}
          setInventory={setInventory}
          locations={locations}
          items={items}
        />
      </Container>
    </>
  );
};

export default App;
