import React from "react";
import styled from "styled-components";

const LocationDescriptionStyle = styled.p`
  margin-bottom: 1rem;
  white-space: pre-line;
`;

const LocationDescription = ({ description }) => {
  return <LocationDescriptionStyle>{description}</LocationDescriptionStyle>;
};

export default LocationDescription;
