import React from "react";
import styled from "styled-components";

const InputAndButtonStyle = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const GameInput = styled.input`
  flex-grow: 1;
  background-color: #333;
  color: #eee;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 5px 0 0 5px;
  font-family: "Inconsolata", monospace;
  margin-right: 0.5rem;
`;

const SubmitButton = styled.button`
  background-color: #333;
  color: #eee;
  border: 1px solid #eee;
  padding: 0.5rem 1rem;
  border-radius: 0 5px 5px 0;
  font-family: "Inconsolata", monospace;
  cursor: pointer;
`;

const InputAndButton = ({
  userInput,
  setUserInput,
  handleAction,
  handleKeyPress,
}) => {
  return (
    <InputAndButtonStyle>
      <GameInput
        type="text"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Enter command (e.g., 'go north', 'take clock')"
      />
      <SubmitButton onClick={handleAction}>Submit</SubmitButton>
    </InputAndButtonStyle>
  );
};

export default InputAndButton;
